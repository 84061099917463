import {EagleApiInterface, EagleModuleApiInterface} from 'kernel/api/api'

export default (api: EagleApiInterface) => {
  api.collection.smsApi = <EagleModuleApiInterface>{
    quota: () => api.request({
      url: `/api/admin/sms/quota`,
      type: 'get',
    }),
    send: (params: AnyObject) => api.request({
      url: `/api/admin/sms/send`,
      type: 'post',
      params,
    }),
  }

  api.collection.smsLogApi = <EagleModuleApiInterface>{
    read: (id: string) => api.request({
      url: `/api/admin/sms-log/${id}`,
      type: 'get',
    }),
    index: (params = {}) => api.request({
      url: `/api/admin/sms-log/index`,
      type: 'get',
      params,
    }),
    delete: (id: string) => api.request({
      url: `/api/admin/sms-log/${id}`,
      type: 'delete',
    }),
    batchDelete: (targets: string[]) => api.request({
      url: `/api/admin/sms-log/batch`,
      type: 'delete',
      params: { ids: targets },
    }),
  }
}
